<template>
  <button type="button">
    <Icon :icon :size class="text-tenant sm:text-gray-500" />
  </button>
</template>

<script setup lang="ts">
import { Size } from "@/elements/enums";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/vue/24/outline";
import { computed, FunctionalComponent } from "vue";

const props = withDefaults(
  defineProps<{
    expanded?: boolean;
    size?: Size;
    icons?: [expanded: FunctionalComponent, notexpanded: FunctionalComponent];
  }>(),
  {
    expanded: false,
    size: "sm",
    icons: () => [ChevronDownIcon, ChevronUpIcon],
  },
);

const icon = computed(() => (props.expanded ? props.icons[0] : props.icons[1]));
</script>
