import env from "@/env";
import axios, { AxiosInstance } from "axios";

const apiBaseURL = env.VITE_API_BASE_URL;

export default class HttpClient {
  static sharedHeaders = {
    "Content-Type": "application/json",
  };

  static tenants = axios.create({
    headers: this.sharedHeaders,
    baseURL: `${apiBaseURL}/educonfig/tenants/`,
  });

  static educonfig = axios.create({ headers: this.sharedHeaders });

  private static services: { client: AxiosInstance; baseUrl: string }[] = [
    { client: this.educonfig, baseUrl: `${apiBaseURL}/educonfig/` },
  ];

  static setCulture(culture: string): void {
    this.services.forEach((service) => {
      // see https://github.com/axios/axios/issues/4193
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      service.client.defaults.headers["Accept-Language"] = culture;
    });
  }

  static setTenant(tenantUri: string | null): void {
    this.getTenantAwareServices().forEach((service) => {
      service.client.defaults.baseURL =
        tenantUri != null
          ? `${service.baseUrl}${encodeURIComponent(tenantUri)}/`
          : service.baseUrl;
    });
  }

  static getTenantAwareServices() {
    return this.services.filter(
      // The tenants endpoint is tenant-unaware, so there's no need to add the tenant uri
      (service) => service.client != HttpClient.tenants,
    );
  }
}
