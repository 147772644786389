import dictionary, { Culture } from "@/dictionary";
import { eduConfigurationServiceClient } from "@/services/eduConfigurationService.client.service";
import HttpClient from "@/utils/httpClient";
import { AvailableLanguagesLocalized } from "lib/eduConfigurationServiceClient";
import { Settings } from "luxon";
import { reactive } from "vue";

const fallbackCulture = "en-US";

export interface AppContext {
  tenant: {
    uri: string;
    languages: AvailableLanguagesLocalized;
    defaultCulture: Culture;
  };
  color: string;
  culture: Culture;
  texts: (typeof dictionary)["en-US"];
}
export const app = reactive<AppContext>({
  tenant: {
    uri: "",
    languages: {} as AvailableLanguagesLocalized,
    defaultCulture: fallbackCulture,
  },
  color: "#404040",
  culture: fallbackCulture,
  texts: dictionary[fallbackCulture],
});

export async function loadAppTenant(uri: string) {
  HttpClient.setTenant(uri);
  const languages = await eduConfigurationServiceClient.getPublicLanguages();
  app.tenant.uri = uri;
  app.tenant.languages = languages;
  app.tenant.defaultCulture =
    (languages.mainLanguage.locale.value as Culture) ?? fallbackCulture;

  setAppCulture(app.tenant.defaultCulture);
}

export function setAppColor(color: string) {
  app.color = color;
}

export function setAppCulture(culture: Culture) {
  HttpClient.setCulture(culture);
  Settings.defaultLocale = culture;
  app.culture = culture;
  app.texts = dictionary[culture];
}
