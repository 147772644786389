<template>
  <select
    v-model="value"
    class="inline-flex items-center justify-start gap-1.5 rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm"
    :class="[
      {
        'py-1 pl-3 pr-8': size === 'sm',
        'py-1.5 pl-3 pr-9': size === 'md',
        'py-2 pl-3 pr-10': size === 'lg',
      },
      {
        'text-sm font-normal': size === 'sm',
        'text-base font-normal': size === 'md',
        'text-lg font-normal': size === 'lg',
      },
      'border-0',
      'outline-none ring-1 ring-inset ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-tenant',
    ]"
  >
    <slot></slot>
  </select>
</template>

<script setup lang="ts">
defineProps<{
  size?: string;
}>();

const value = defineModel<string>();
</script>
