import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/index";
import "@/style.css";
import register from "@/elements";
import { Settings } from "luxon";

Settings.defaultZone = "Europe/Amsterdam";

const app = createApp(App);
app.use(router);
register(app);
app.mount("#app");
