import { AxiosRequestConfig } from "axios";

export class ClientBase {
  protected transformOptions = (
    options: AxiosRequestConfig
  ): Promise<AxiosRequestConfig> => {
    options.url = options.url
      ? options.url.replace("/{tenant}/", "/")
      : options.url;
    return Promise.resolve(options);
  };
}
