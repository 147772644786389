<template>
  <RouterView />
</template>

<script setup lang="ts">
import { watch } from "vue";
import { toRGB } from "@/utils/color";
import { app } from "@/app.context";

watch(
  () => app.color,
  (color) => {
    document.body.style.setProperty("--color-tenant", toRGB(color));
  },
  { immediate: true },
);
</script>
