<template>
  <div
    :class="[
      'flex items-center justify-center gap-0.5 rounded-full py-1.5',
      props.variant !== 'transparent' ? 'px-3' : '',
      'text-xs font-medium',
      {
        'bg-tenant/10 text-tenant': variant === 'tenant',
        'bg-transparent text-gray-500': variant === 'transparent',
        'bg-gray-100 text-gray-600': variant === 'gray',
      },
    ]"
  >
    <component
      :is="icon"
      v-if="icon"
      :class="[
        'h-4 w-4 flex-shrink-0 stroke-2 text-gray-400',
        {
          'text-tenant/75': variant === 'tenant',
          'text-gray-400': variant === 'transparent',
          'text-gray-500': variant === 'gray',
        },
      ]"
    />
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { FunctionalComponent } from "vue";

const props = withDefaults(
  defineProps<{
    variant?: "gray" | "tenant" | "transparent";
    icon?: FunctionalComponent;
  }>(),
  {
    variant: "gray",
    icon: undefined,
  },
);
</script>
