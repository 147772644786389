// region: Lazy Loaded View Components
const ActivityList = () => import("@/views/ActivityList.vue");

import {
  createRouter,
  createWebHistory,
  RouteLocationNormalizedGeneric,
  RouteRecordRaw,
  RouterView,
} from "vue-router";
import NotFound from "@/views/NotFound.vue";
import { RouteNames } from "./routeNames";
import env from "@/env";
import { Culture } from "@/dictionary";

export type ActivityListRouteProps = {
  tenant: string;
  culture?: Culture;
  activityTypeId?: string;
  areaOfInterestId?: string;
  studyProgramLanguage?: string;
  studyProgramId?: string;
  view: ActivityListView;
};

export type ActivityListView = "byMonth" | "byStudyPrograms";

function toActivityListRouteProps(
  route: RouteLocationNormalizedGeneric,
  view: ActivityListView,
): ActivityListRouteProps {
  return {
    tenant: route.params.tenant,
    culture: route.query.culture,
    activityTypeId: route.query.activityTypeId,
    areaOfInterestId: route.query.areaOfInterestId,
    studyProgramLanguage: route.query.studyProgramLanguage,
    studyProgramId: route.query.studyProgramId,
    view,
  } as ActivityListRouteProps;
}

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    components: {
      default: RouterView,
    },
    children: [
      {
        path: "activities",
        component: RouterView,
        children: [
          {
            path: ":tenant",
            component: ActivityList,
            props: (route) => toActivityListRouteProps(route, "byMonth"),
          },
        ],
      },
      {
        path: "studyprograms",
        component: RouterView,
        children: [
          {
            path: ":tenant",
            component: ActivityList,
            props: (route) =>
              toActivityListRouteProps(route, "byStudyPrograms"),
          },
        ],
      },
    ],
  },
  {
    path: "/:pathMatch(.*)",
    name: RouteNames.NOT_FOUND,
    component: NotFound,
    meta: {
      title: "not-found",
      doesNotRequireTenant: true,
      doesNotRequireAuthentication: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(env.BASE_URL),
  routes,
});

router.beforeEach((to, _from, next) => {
  const title = to.meta?.title as string | undefined;
  document.title = title ? `${title}` : "Activiteiten";
  next();
});

export default router;
