export const toRGB = (colorHex: string) => {
  if (colorHex) {
    // Convert hex value to rgb
    const hex = colorHex;
    const r = parseInt(hex.substring(1, 3), 16);
    const g = parseInt(hex.substring(3, 5), 16);
    const b = parseInt(hex.substring(5, 7), 16);

    return `${r} ${g} ${b}`;
  }
  return "64 64 64";
};
